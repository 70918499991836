:root {
  --border-radius: 24px;
  --box-padding: 24px;
  --box-gap: 24px;
  --main-color: #28245F;
  --subheader-color: #ACAABA;
  --main-error-color: rgb(236,26,88);
  --tab-burrot-backgroud: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(90deg, #EC1A58 0.02%, #E61861 33.34%, #D5137B 94.27%, #D3137D 100%)
}

html * {
  font-family: 'Popins', sans-serif !important;
}

.centralize {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-page {
  background-color: #F9F9FF;
  width: 100%;
  min-height: calc(100vh - 80px);
  padding: 40px 40px 20px !important;
  overflow: auto;
}

.card-details {
  background-color: #F9F9FF;
  position: relative;
  top: -80px;
  width: 100%;
  min-height: 100vh;
}

#load {
  position: absolute;
  width: 600px;
  height: 36px;
  left: 50%;
  top: 40%;
  margin-left: -300px;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#load div {
  position: absolute;
  width: 20px;
  height: 36px;
  opacity: 0;
  font-family: Helvetica, Arial, sans-serif;
  animation: move 2s linear infinite;
  -o-animation: move 2s linear infinite;
  -moz-animation: move 2s linear infinite;
  -webkit-animation: move 2s linear infinite;
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  color: #031e44;
}

#load div:nth-child(2) {
  animation-delay: 0.2s;
  -o-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

#load div:nth-child(3) {
  animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

#load div:nth-child(4) {
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

#load div:nth-child(5) {
  animation-delay: 0.8s;
  -o-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}

#load div:nth-child(6) {
  animation-delay: 1s;
  -o-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

#load div:nth-child(7) {
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}

@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  35% {
    left: 41%;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  65% {
    left: 59%;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    left: 100%;
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

@-moz-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  35% {
    left: 41%;
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  65% {
    left: 59%;
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    left: 100%;
    -moz-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

@-webkit-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  35% {
    left: 41%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  65% {
    left: 59%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    left: 100%;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

@-o-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  35% {
    left: 41%;
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  65% {
    left: 59%;
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    left: 100%;
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: 0;
  }
}

.count-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  max-width: 100px;
  height: 24px;
  background-color: #FBE7F2;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  color: rgb(236, 26, 88);
}

.form-actions {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.form-group-buttons {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

#root ::-webkit-scrollbar,
#dialog-window ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
#root ::-webkit-scrollbar-track,
#dialog-window ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
#root ::-webkit-scrollbar-thumb,
#dialog-window ::-webkit-scrollbar-thumb {
  background: rgb(236, 26, 88);
  border-radius: 10px;
}

.rotate-icon {
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hover-box-border:hover {
  border: 1px solid rgb(236, 26, 88);
}

.vip-status-badge {
  height: 28px;
  min-width: 85px;
  background: linear-gradient(90deg, #EC1A58 0.02%, #E61861 33.34%, #D5137B 94.27%, #D3137D 100%);
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  color: white;
  font-weight: 400;
  font-size: 16px;
}

.data-badge {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  max-width: 120px;
  height: 24px;
  background-color: #FBE7F2;
  color: rgb(242, 10, 79);
  font-weight: 600;
  font-size: 12px;
}

.kanban-stage {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 385px;
  height: calc(100vh - 80px - 180px);
  background: white;
  border-radius: 24px;
  box-shadow: 0px 10px 15px rgba(8, 79, 180, 0.05);
}

.row-even-space {
  display: flex;
  width: 100%;
  gap: var(--box-gap);
  justify-content: space-between;
}

.helper-text-box {
  display: flex;
  gap: 4px;
  color: #C9C8D0;
  font-size: 12px;
  font-weight: 400
}

.data-box {
  display: flex;
  flex-flow: column;
  min-height: 108px;
  width: 100%;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
  padding: var(--box-padding);
  gap: var(--box-gap)
}

.data-box-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--main-color);
/* iGA/Header/H4 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
}

.tab-button-selected {
  background: var(--tab-burrot-backgroud);
  border: none !important;
}

.tab-button-selected:hover {
  background: none;
}

.tab-button {
  color: var(--main-color) !important;
  background: none !important;
  border: none !important;
}

.tab-button:hover {
  background: var(--tab-burrot-backgroud) !important;
  color: white !important;
}