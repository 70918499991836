.header-image {
  width: 100%;
  height: 388px;
}

.content-container {
  display: flex;
  gap: 40px;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 0;
}

.header {
  font-weight: 600;
  font-size: 24px;
  color: white;
  display: flex;
  margin-left: 40px;
  margin-top: 20px;
}

.back-button {
  color: white;
}

.back-icon {
  color: white;
}

.title {
  margin-left: 16px;
}

.player-info {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 40px;
}

.name-container {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 24px;
  width: 100%;
}

.card-grid {
  padding-left: 40px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  align-items: center;
  gap: 20px;
}

.card {
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
  background: rgba(253, 243, 248, 0.3);
  height: 88px;
  border-radius: 16px;
  grid-column: span 1;
}

.double-card {
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
  background: rgba(253, 243, 248, 0.3);
  height: 88px;
  border-radius: 16px;
  grid-column: span 2;
  margin-right: 40px;
  margin-left: 40px;
}

.tabs-container {
  padding-left: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: white;
  border-radius: 16px;
  height: 88px;
  margin-left: 40px;
  margin-right: 40px;
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
}

.tabs {
  border-bottom: 1px;
  border-color: divider;
  background-color: white;
}

.info-section {
  border-bottom: 1px;
  border-color: divider;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
  margin-left: 24px;
  margin-top: 40px;
  height: 366px;
  margin-right: 40px;
}

.info-section-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 11px;
  line-height: 22px;
  text-align: left;
  padding: 24px;
}

.info-section + .info-section {
  margin-bottom: 20px;
  height: 262px;
}

.info-section:last-child {
  margin-bottom: 40px;
}

.info-section .info-section-title {
  margin-bottom: 11px;
}
