.kanban-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border: 1px solid #F2F1F9;
    border-radius: 16px;
    box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
    width: 100%;
    height: 160px
}

.kanban-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.kanban-title {
    font-size: 14px;
    font-weight: 600;
    cursor: default;
    color: var(--main-color);
}

.kanban-secondary-details {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 400;
    cursor: default;
    color: var(--subheader-color);
}

.kanban-item-action-row {
    display: flex;
    gap: 8px;
    color: var(--main-error-color);
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}

.kanban-action-icon {
    width: 16px !important;
    height: 16px !important;
    color: var(--main-error-color);
}