.journey-details-text {
    display: flex;
    align-items: flex-end;
    color: var(--subheader-color);
    font-weight: 400;
    font-size: 14px;
    gap: 8px;
}

.journey-details-title {
    font-weight: 700;
    font-size: 16px;
    color: var(--main-color);
}