.stat-container {
  border: 2px solid var(--subheader-color);
  border-radius: 15px;
  padding: 0px 5px 0px 5px;
  font-size: 14px;
  cursor: pointer;

  &--clicked {
    background-color: var(--subheader-color);
    color: white;
  }
}

.stat-indicator {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50px;
  margin-right: 4px;
}
