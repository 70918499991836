.popup__html-content-step {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.html-parser {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 500px;
  flex: 1;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: #0d0f36;

    &--desktop {
      width: 100%;
    }

    &--mobile {
      padding: 0 10px;
      width: 400px;
    }
  }

  // > :first-child {
  //   border: 1px solid red;
  // }
}
