.container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 40px;
  padding-left: 40px;
  gap: 24px;
}

.add-comments-container {
  grid-column: span 1;
  margin-top: 40px;
  margin-bottom: 40px;
  border-bottom: 1;
  border-color: divider;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
  margin-top: 40px;
  height: 366px;
}

.comments-container {
  grid-column: span 3;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  border-bottom: 1;
  border-color: divider;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
  margin-top: 40px;
  height: 366px;
  margin-right: 24px;
  height: 768px;
  overflow: auto;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 40px;
  padding-left: 40px;
  gap: 24px;
}

.custom-placeholder ::placeholder {
  color: red;
}
