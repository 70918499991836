:root {
  --border-radius: 24px;
  --box-padding: 24px;
  --box-gap: 24px;
  --main-color: #28245f;
  --subheader-color: #acaaba;
  --main-error-color: #ec1a58;
  --tab-burrot-backgroud: linear-gradient(0deg, rgba(255, 255, 255, .3) 0%, rgba(255, 255, 255, .3) 100%), linear-gradient(90deg, #ec1a58 0.02%, #e61861 33.34%, #d5137b 94.27%, #d3137d 100%);
}

html * {
  font-family: Popins, sans-serif !important;
}

.centralize {
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-page {
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: #f9f9ff;
  overflow: auto;
  padding: 40px 40px 20px !important;
}

.card-details {
  width: 100%;
  min-height: 100vh;
  background-color: #f9f9ff;
  position: relative;
  top: -80px;
}

#load {
  width: 600px;
  height: 36px;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  margin-left: -300px;
  position: absolute;
  top: 40%;
  left: 50%;
  overflow: visible;
}

#load div {
  width: 20px;
  height: 36px;
  opacity: 0;
  color: #031e44;
  font-family: Helvetica, Arial, sans-serif;
  animation: 2s linear infinite move;
  position: absolute;
  transform: rotate(180deg);
}

#load div:nth-child(2) {
  animation-delay: .2s;
}

#load div:nth-child(3) {
  animation-delay: .4s;
}

#load div:nth-child(4) {
  animation-delay: .6s;
}

#load div:nth-child(5) {
  animation-delay: .8s;
}

#load div:nth-child(6) {
  animation-delay: 1s;
}

#load div:nth-child(7) {
  animation-delay: 1.2s;
}

@keyframes move {
  0% {
    opacity: 0;
    left: 0;
  }

  35% {
    opacity: 1;
    left: 41%;
    transform: rotate(0);
  }

  65% {
    opacity: 1;
    left: 59%;
    transform: rotate(0);
  }

  100% {
    opacity: 0;
    left: 100%;
    transform: rotate(-180deg);
  }
}

.count-badge {
  min-width: 20px;
  max-width: 100px;
  height: 24px;
  color: #ec1a58;
  background-color: #fbe7f2;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.form-actions {
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
}

.form-group-buttons {
  align-items: center;
  column-gap: 16px;
  display: flex;
}

#root ::-webkit-scrollbar, #dialog-window ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#root ::-webkit-scrollbar-track, #dialog-window ::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px gray;
}

#root ::-webkit-scrollbar-thumb, #dialog-window ::-webkit-scrollbar-thumb {
  background: #ec1a58;
  border-radius: 10px;
}

.rotate-icon {
  animation: 2s linear infinite rotate;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hover-box-border:hover {
  border: 1px solid #ec1a58;
}

.vip-status-badge {
  height: 28px;
  min-width: 85px;
  color: #fff;
  background: linear-gradient(90deg, #ec1a58 .02%, #e61861 33.34%, #d5137b 94.27%, #d3137d 100%);
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 16px;
  font-weight: 400;
}

.data-badge {
  min-width: 20px;
  max-width: 120px;
  height: 24px;
  color: #f20a4f;
  background-color: #fbe7f2;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  display: flex;
}

.kanban-stage {
  width: 385px;
  height: calc(100vh - 260px);
  background: #fff;
  border-radius: 24px;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  display: flex;
  box-shadow: 0 10px 15px rgba(8, 79, 180, .05);
}

.row-even-space {
  width: 100%;
  gap: var(--box-gap);
  justify-content: space-between;
  display: flex;
}

.helper-text-box {
  color: #c9c8d0;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.data-box {
  min-height: 108px;
  width: 100%;
  border-radius: var(--border-radius);
  padding: var(--box-padding);
  gap: var(--box-gap);
  background: #fff;
  flex-flow: column;
  display: flex;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.data-box-title {
  width: 100%;
  color: var(--main-color);
  text-transform: capitalize;
  justify-content: space-between;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  display: flex;
}

.tab-button-selected {
  background: var(--tab-burrot-backgroud);
  border: none !important;
}

.tab-button-selected:hover {
  background: none;
}

.tab-button {
  color: var(--main-color) !important;
  background: none !important;
  border: none !important;
}

.tab-button:hover {
  background: var(--tab-burrot-backgroud) !important;
  color: #fff !important;
}

.filter-card {
  width: 245px;
  height: 336px;
  filter: drop-shadow(0 10px 15px rgba(125, 118, 226, .08));
  border-radius: 16px;
  display: flex;
  box-shadow: 0 10px 30px rgba(78, 72, 177, .3);
}

.filled-card-selected, .filled-card:hover {
  position: relative;
  bottom: 15px;
}

.filter-card-header {
  height: 60px;
  width: 100%;
  color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  justify-content: space-between;
  padding: 12px;
  display: flex;
}

.kanban-card {
  width: 100%;
  height: 160px;
  border: 1px solid #f2f1f9;
  border-radius: 16px;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  display: flex;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.kanban-card-secondary-text {
  color: var(--subheader-color);
  font-size: 14px;
  font-weight: 400;
}

.kanban-card-text {
  color: var(--main-color);
  font-weight: 400;
  font-size: "16px";
}

.account-details-container {
  width: 760px;
  height: 792px;
  background: #fff;
  border-radius: 24px;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  display: flex;
  box-shadow: 0 10px 15px rgba(8, 79, 180, .05);
}

.account-details-item {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 24px;
  display: flex;
}

.account-details-item-edit-button-text {
  text-transform: capitalize;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.account-details-item-avatar {
  color: #d3137d;
  width: 80px;
  height: 80px;
}

.account-details-item-edit-button {
  width: 27px;
  height: 20px;
  color: "error.main";
}

.account-details-update-image-button {
  text-transform: capitalize;
  color: #d3137d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.segment-group-box {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 24px;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px 10px;
  display: flex;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.segment-group-box-name {
  color: #28245f;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
}

.segment-group-box-actions {
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 16px;
  padding-top: 5px;
  display: flex;
}

.journey-stats-row {
  gap: 10px;
  margin-bottom: 40px;
  display: flex;
}



.stat-container {
  border: 2px solid var(--subheader-color);
  cursor: pointer;
  border-radius: 15px;
  padding: 0 5px;
  font-size: 14px;
}

.stat-container--clicked {
  background-color: var(--subheader-color);
  color: #fff;
}

.stat-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  margin-right: 4px;
  display: inline-block;
}

.journey-details-text {
  color: var(--subheader-color);
  align-items: flex-end;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.journey-details-title {
  color: var(--main-color);
  font-size: 16px;
  font-weight: 700;
}

.border-step:before {
  content: "";
  width: 80%;
  height: 3px;
  background-color: #dfdee7;
  position: absolute;
  top: 0;
  left: 40%;
  transform: translateX(-40%);
}

.border-step {
  padding-top: 20px;
  position: relative;
}

.step-box {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  display: flex;
}

.step-box-name {
  height: 80px;
  max-width: 210px;
  cursor: pointer;
  color: var(--main-color);
  border: 1px solid #f2f1f9;
  border-radius: 24px;
  padding: 24px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 10px 15px rgba(8, 79, 180, .05);
}

.step-box-name-selected {
  height: 80px;
  max-width: 210px;
  cursor: pointer;
  color: var(--main-color);
  border: 1px solid #ec1a58;
  border-radius: 24px;
  padding: 24px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 10px 15px rgba(8, 79, 180, .05);
}

.journey-step-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.step-container {
  flex-direction: column;
  gap: 25px;
  display: flex;
}

.step-title {
  color: var(--main-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
}

.container {
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  transition: all 1s;
}

.desktop {
  width: 90%;
}

.tablet {
  width: 768px;
}

.mobile {
  width: 480px;
}

.kanban-card {
  width: 100%;
  height: 160px;
  border: 1px solid #f2f1f9;
  border-radius: 16px;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  display: flex;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.kanban-header {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.kanban-title {
  cursor: default;
  color: var(--main-color);
  font-size: 14px;
  font-weight: 600;
}

.kanban-secondary-details {
  cursor: default;
  color: var(--subheader-color);
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.kanban-item-action-row {
  color: var(--main-error-color);
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.kanban-action-icon {
  color: var(--main-error-color);
  width: 16px !important;
  height: 16px !important;
}

.popup__html-content-step {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.html-parser {
  width: 100%;
  min-height: 500px;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.html-parser__content {
  height: 500px;
  background-color: #0d0f36;
  justify-content: center;
  align-items: center;
  display: flex;
}

.html-parser__content--desktop {
  width: 100%;
}

.html-parser__content--mobile {
  width: 400px;
  padding: 0 10px;
}

.popup__details-step {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.info-box {
  height: 108px;
  border-radius: var(--border-radius);
  padding: var(--box-padding);
  gap: var(--box-gap);
  background: #fff;
  display: flex;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.info-box-title {
  color: var(--main-color);
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.info-box-percent-trend {
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
}

.info-box-value {
  color: var(--main-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
}

.info-box-value-pink {
  color: var(--main-error-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
}

.info-box-legend {
  color: var(--subheader-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
}

.info-icon-box {
  width: 42px;
  height: 46px;
  -webkit-backdrop-filter: blur(5.4px);
  backdrop-filter: blur(5.4px);
  border-radius: 4px;
  display: flex;
  transform: rotate(15deg);
  box-shadow: inset -5.4px 5.4px 5.4px rgba(255, 255, 255, .15), inset 0 -5.4px 5.4px #ff8e8a;
}

.info-icon {
  color: rgba(255, 141, 175, .5);
  font-size: 46px;
  position: relative;
  right: 9px;
  transform: rotate(-15deg);
}

.container {
  width: 100%;
  background-color: #f9f9ff;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-top: 40px;
  padding-left: 40px;
  display: grid;
  position: absolute;
  left: 0;
}

.info-cards-container {
  flex-direction: column;
  grid-column: span 1;
  gap: 24px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
}

.right-column-container {
  flex-direction: column;
  grid-column: span 3;
  gap: 24px;
  display: flex;
}

.player-stats-box {
  border-bottom: 1px;
  border-color: divider;
  min-height: 366px;
  background-color: #fff;
  border-radius: 16px;
  margin-top: 40px;
  margin-right: 24px;
  padding: 24px;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.kpis-box {
  border-bottom: 1px;
  border-color: divider;
  background-color: #fff;
  border-radius: 16px;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 24px;
  padding: 24px;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.comments-timeLine-container {
  justify-content: space-between;
  gap: 24px;
  margin-top: -14px;
  margin-bottom: 40px;
  margin-right: 24px;
  display: flex;
}

.comments-box {
  border-bottom: 1px;
  border-color: divider;
  height: 770px;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  flex-direction: column;
  display: flex;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.timeLine-box {
  border-bottom: 1px;
  border-color: divider;
  height: 770px;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.header-image {
  width: 100%;
  height: 388px;
}

.content-container {
  width: 100%;
  flex-direction: column;
  gap: 40px;
  display: flex;
  position: absolute;
  top: 0;
}

.header {
  color: #fff;
  margin-top: 20px;
  margin-left: 40px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
}

.back-button, .back-icon {
  color: #fff;
}

.title {
  margin-left: 16px;
}

.player-info {
  align-items: center;
  gap: 20px;
  margin-left: 40px;
  display: flex;
}

.name-container {
  color: #fff;
  width: 100%;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  display: flex;
}

.card-grid {
  grid-template-columns: repeat(5, minmax(0, 1fr));
  align-items: center;
  gap: 20px;
  padding-left: 40px;
  display: grid;
}

.card {
  height: 88px;
  background: rgba(253, 243, 248, .3);
  border-radius: 16px;
  grid-column: span 1;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.double-card {
  height: 88px;
  background: rgba(253, 243, 248, .3);
  border-radius: 16px;
  grid-column: span 2;
  margin-left: 40px;
  margin-right: 40px;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.tabs-container {
  height: 88px;
  background-color: #fff;
  border-radius: 16px;
  align-items: center;
  gap: 20px;
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 40px;
  display: flex;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.tabs {
  border-bottom: 1px;
  border-color: divider;
  background-color: #fff;
}

.info-section {
  border-bottom: 1px;
  border-color: divider;
  height: 366px;
  background-color: #fff;
  border-radius: 16px;
  margin-top: 40px;
  margin-left: 24px;
  margin-right: 40px;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.info-section-title {
  text-align: left;
  margin-bottom: 11px;
  padding: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.info-section + .info-section {
  height: 262px;
  margin-bottom: 20px;
}

.info-section:last-child {
  margin-bottom: 40px;
}

.info-section .info-section-title {
  margin-bottom: 11px;
}

.container {
  width: 100%;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
  margin-top: 40px;
  padding-left: 40px;
  display: grid;
  position: absolute;
  left: 0;
}

.add-comments-container {
  border-bottom: 1px;
  border-color: divider;
  height: 366px;
  background-color: #fff;
  border-radius: 16px;
  grid-column: span 1;
  margin-top: 40px;
  margin-bottom: 40px;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.comments-container {
  border-bottom: 1px;
  border-color: divider;
  height: 366px;
  height: 768px;
  background-color: #fff;
  border-radius: 16px;
  flex-direction: column;
  grid-column: span 3;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 24px;
  display: flex;
  overflow: auto;
  box-shadow: 0 10px 15px rgba(125, 118, 226, .08);
}

.container {
  width: 100%;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 24px;
  margin-top: 40px;
  padding-left: 40px;
  display: grid;
  position: absolute;
  left: 0;
}

.custom-placeholder ::placeholder {
  color: red;
}

/*# sourceMappingURL=index.15a94e10.css.map */
