.account-details-container {
    display: flex;
    flex-Direction: column;
    padding: 24px;
    gap: 24px;
    width: 760px;
    height: 792px;
    border-Radius: 24px;
    background: #FFFFFF;
    box-Shadow: 0px 10px 15px rgba(8, 79, 180, 0.05);
}

.account-details-item {
    display: flex;
    flex-Direction: row;
    justify-Content: space-between;
    align-Items: flex-start;
    padding: 0px 0px 24px;
}

.account-details-item-edit-button-text {
    font-Family: Inter;
    font-Style: normal;
    font-Weight: 700;
    font-Size: 14px;
    text-Transform: capitalize;
}

.account-details-item-avatar {
    color: #D3137D;
    width: 80px;
    height: 80px;
}

.account-details-item-edit-button {
    width: 27px;
    height: 20px;
    color: 'error.main';
}

.account-details-update-image-button {
    font-Family: Inter;
    font-Style: normal;
    font-Weight: 700;
    font-Size: 14px;
    text-Transform: capitalize;
    color: #D3137D;
}