.info-box {
  display: flex;
  height: 108px;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: 0px 10px 15px 0px rgba(125, 118, 226, 0.08);  
  padding: var(--box-padding);
  gap: var(--box-gap);
}

.info-box-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--main-color);
  text-align: left;
}

.info-box-percent-trend {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.info-box-value {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: var(--main-color);
}
.info-box-value-pink {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;  color: var(--main-error-color);
}

.info-box-legend {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: var(--subheader-color);
}

.info-icon-box {
  width: 42px;
  height: 46px;
  transform: rotate(15deg);
  border-radius: 4px;
  display: flex;
  box-shadow: -5.4px 5.4px 5.4px 0px rgba(255, 255, 255, 0.15) inset, 0px -5.4px 5.4px 0px #FF8E8A inset;
  backdrop-filter: blur(5.400000095367432px);
}

.info-icon {
  transform: rotate(-15deg);
  color:   rgba(255, 141, 175, 0.5); 
  font-size: 46px;
  position: relative;
  right: 9px;
}
