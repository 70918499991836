.filter-card {
    display: flex;
    width: 245px;
    height: 336px;
    border-radius: 16px;
    filter: drop-shadow(0px 10px 15px rgba(125, 118, 226, 0.08));
    box-shadow: 0px 10px 30px rgba(78, 72, 177, 0.3);
}

.filled-card-selected {
    position: relative;
    bottom: 15px;
}

.filled-card:hover {
    position: relative;
    bottom: 15px;
}

.filter-card-header {
    display: flex;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    padding: 12px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    color: white;
}