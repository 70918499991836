.segment-group-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 24px;
    padding: 24px 24px 10px 24px;
    background-color: white;
    box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
    border: 1px solid white;
}

.segment-group-box-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #28245F;
}

.segment-group-box-actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 16px;
    padding-top: 5px;
}