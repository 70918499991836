.step-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.step-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: var(--main-color);
}

.container {
    /* General styling */
    background-color: whitesmoke;
    padding: 1rem;
    border-radius: 5px;
    transition: all 1s;

    /* Center the container */
    margin-left: auto;
    margin-right: auto;
}

.desktop {
    width: 90%;
}

.tablet {
    width: 768px;
}

.mobile {
    width: 480px;
}