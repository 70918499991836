.border-step::before {
    content: "";
    position: absolute;
    top: 0;
    left: 40%;
    transform: translateX(-40%);
    width: 80%;
    height: 3px;
    background-color: #DFDEE7;
    /* border color */
}

.border-step {
    position: relative;
    padding-top: 20px;
}

.step-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

.step-box-name {
    height: 80px;
    max-width: 210px;
    border-radius: 24px;
    border: 1px solid #F2F1F9;
    padding: 24px;
    box-shadow: 0px 10px 15px rgba(8, 79, 180, 0.05);
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: var(--main-color);
}

.step-box-name-selected {
    height: 80px;
    max-width: 210px;
    border-radius: 24px;
    border: 1px solid rgb(236, 26, 88);
    padding: 24px;
    box-shadow: 0px 10px 15px rgba(8, 79, 180, 0.05);
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: var(--main-color);
}

.journey-step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 15px 0px rgba(125, 118, 226, 0.08);
}