.container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 40px;
  padding-left: 40px;
  background-color: #f9f9ff;
}

.info-cards-container {
  grid-column: span 1;
  gap: 24px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.right-column-container {
  grid-column: span 3;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.player-stats-box {
  border-bottom: 1;
  border-color: divider;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
  margin-top: 40px;
  min-height: 366px;
  margin-right: 24px;
  padding: 24px;
}

.kpis-box {
  border-bottom: 1;
  border-color: divider;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
  /* height: 332px; */
  margin-right: 24px;
  margin-bottom: 20px;
  flex-direction: column;
  padding: 24px;
}

.comments-timeLine-container {
  margin-right: 24px;
  margin-bottom: 40;
  margin-top: -14px;
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.comments-box {
  border-bottom: 1;
  border-color: divider;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
  height: 770px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.timeLine-box {
  border-bottom: 1;
  border-color: divider;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 10px 15px rgba(125, 118, 226, 0.08);
  height: 770px;
  width: 100%;
}
